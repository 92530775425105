import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Comments.module.sass";
import Card from "../../../components/Card";
import api from "../../../api";

const Comments = ({ className }) => {
  const [latestCard, setLatestCard] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLatestCardDetails = async () => {
      try {
        const response = await api.get("/cards/latest");
        setLatestCard(response.data);
      } catch (err) {
        console.error("Error fetching latest card details:", err);
        setError(err);
      }
    };

    fetchLatestCardDetails();
  }, []); // Runs only once when the component mounts

  console.log(latestCard);

  const branch = JSON.parse(localStorage.getItem('branch'));
  const branchName = branch?.name;
  const encodedUrl = latestCard?.url;
  const date = latestCard?.created_at;
  const formatedDate = new Date(date).toLocaleDateString();

  if (error) {
    return <div>Error loading card details.</div>;
  }

  return (
    <Card
      className={cn(styles.card, className)}
      title={branchName}
      classTitle="title-yellow"
    >
      <div className={styles.comments}>
        <div className={styles.details}>
          <div className={styles.line}>
            <div className={styles.info}>
              <strong>Created: </strong>
              <span>{formatedDate || "Loading..."}</span>
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.info}>
              <strong>The encoded URL is: </strong>
              {encodedUrl ? (
                <a href={encodedUrl} target="_blank" rel="noopener noreferrer">
                  {encodedUrl}
                </a>
              ) : (
                "Loading..."
              )}
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.info}>
              <strong>Media Used: </strong>
              <span>{latestCard?.type_of_use || "Loading..."}</span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Comments;
