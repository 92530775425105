import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./styles/app.sass";
import Header from "./components/Header/index"; // Import Header component
import Page from "./components/Page";
import Page2 from "./components/Page2/index";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import Customers from "./screens/Customers";
import CustomerList from "./screens/CustomerList";
import Shop from "./screens/Shop";
import Settings from "./screens/Settings";
import Promote from "./screens/Promote";
import Login from "./screens/Login";
import api from "./api"; // Import the api module

function App() {
  const navigate = useNavigate();

  // Check if the user is already logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Validate the token with the server and get user data
      api.get('/user')
        .then((response) => {
          localStorage.setItem('role', response.data.role); // Store the user role
        })
        .catch(() => {
          // If there's an error, it means the token is invalid or expired
          localStorage.clear(); // Clear any invalid token or user data
        });
    }
  }, []);

  console.log('token is: ', localStorage.getItem('token'));

  // Handle login by storing the token and setting the role
  const handleLogin = (email, password) => {
    api.post("/login", { email, password })
      .then((response) => {
        const { token, user } = response.data;

        // Store the token and user data in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        localStorage.setItem('role', user.role); // Store the user role

        // Redirect based on user role
        navigate("/products/dashboard");
      })
      .catch(() => {
        alert("Invalid email or password");
      });
  };

  console.log('is authenticated: ', localStorage.getItem('token') ? true : false);

  return (
    <div>
      {/* Show the header with logout functionality only if authenticated */}
      { localStorage.getItem('token') && <Header/>}
      {/* Pass the handleLogout to Header */}
      {!localStorage.getItem('token') ? ( // Check if the user is authenticated
        <Login onLogin={handleLogin} /> // Show the Login page if not authenticated
      ) : (
        <Routes>
          <Route path="/">
            {localStorage.getItem('role') === "admin" && (
              <>
                <Route
                  index
                  element={
                    <Page title="Dashboard">
                      <Home />
                    </Page>
                  }
                />
                <Route
                  path="products/dashboard"
                  element={
                    <Page title="Scan">
                      <ProductsDashboard />
                    </Page>
                  }
                />
                <Route
                  path="products/add"
                  element={
                    <Page title="New product">
                      <NewProduct />
                    </Page>
                  }
                />
                <Route
                  path="products/drafts"
                  element={
                    <Page title="Drafts">
                      <Drafts />
                    </Page>
                  }
                />
                <Route
                  path="products/released"
                  element={
                    <Page title="Released">
                      <Released />
                    </Page>
                  }
                />
                <Route
                  path="customers/overview"
                  element={
                    <Page title="Location">
                      <Customers />
                    </Page>
                  }
                />
                <Route
                  path="customers/customer-list"
                  element={
                    <Page title="Customer list">
                      <CustomerList />
                    </Page>
                  }
                />
                <Route
                  path="shop"
                  element={
                    <Page wide>
                      <Shop />
                    </Page>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <Page title="Settings">
                      <Settings />
                    </Page>
                  }
                />
                <Route
                  path="promote"
                  element={
                    <Page title="Promote">
                      <Promote />
                    </Page>
                  }
                />
              </>
            )}
              {localStorage.getItem('role') === "user" && (
              <>
                <Route
                  index
                  element={
                    <Page2 title="Dashboard">
                      <Home />
                    </Page2>
                  }
                />
                <Route
                  path="products/dashboard"
                  element={
                    <Page2 title="Scan">
                      <ProductsDashboard />
                    </Page2>
                  }
                />
                <Route
                  path="products/add"
                  element={
                    <Page2 title="New product">
                      <NewProduct />
                    </Page2>
                  }
                />
                <Route
                  path="products/drafts"
                  element={
                    <Page2 title="Drafts">
                      <Drafts />
                    </Page2>
                  }
                />
                <Route
                  path="products/released"
                  element={
                    <Page2 title="Released">
                      <Released />
                    </Page2>
                  }
                />
                <Route
                  path="customers/overview"
                  element={
                    <Page2 title="Location">
                      <Customers />
                    </Page2>
                  }
                />
                <Route
                  path="customers/customer-list"
                  element={
                    <Page2 title="Customer list">
                      <CustomerList />
                    </Page2>
                  }
                />
                <Route
                  path="shop"
                  element={
                    <Page2 wide>
                      <Shop />
                    </Page2>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <Page2 title="Settings">
                      <Settings />
                    </Page2>
                  }
                />
                <Route
                  path="promote"
                  element={
                    <Page2 title="Promote">
                      <Promote />
                    </Page2>
                  }
                />
              </>
            )}
          </Route>
        </Routes>
      )}
    </div>
  );
}

export default App;
