import React, { useState, useEffect } from "react";
import styles from "./Shop.module.sass";
import RecentPost from "../Promote/RecentPost";
import NewUsersChart from "../Customers/UserChart"; // Ensure this path is correct
import LocationMap from "../../components/Mapsjs/LocationMap";
import api from "../../api";

const Shop = () => {
  // Initialize with sample data
  const [newUsers, setNewUsers] = useState(); // Sample new users count
  const [totalUsers, setTotalUsers] = useState(); // Sample total users count
  const [currentMonth, setCurrentMonth] = useState(""); // Sample month

  useEffect(() => {
    // Fetch data from the backend
    const fetchData = async () => {
      try {
        const response = await api.get("/scans/stats/visitor");

        const data = await response.data;

        setNewUsers(data.newUsersCount);
        setTotalUsers(data.totalUsersCount);
        setCurrentMonth(data.month);
      } catch (error) {
        console.error("Error fetching branch statistics:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <LocationMap />
      <div className={styles.row}>
        <div className={styles.col}>
          {/* Integrate NewUsersChart */}
          <div className={styles.chartContainer}>
            <NewUsersChart
              newUsers={newUsers}
              totalUsers={totalUsers}
              month={currentMonth}
            />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.shop}>
            <RecentPost />
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;
