import React, { useState, useEffect } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown"; // If you're using it elsewhere
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Row from "./Row"; // If Row component is still relevant
import NewPost from "./NewPost";
import { useBranches } from "../../../mocks/posts";

// Possible intervals for the dropdown
const intervals = ["Last 7 days", "This month", "All time"];

const RecentPost = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(true); // Set loading to true initially

  // Use the custom hook to fetch branches
  const posts = useBranches(); // Fetch branches data

  useEffect(() => {
    if (posts.length > 0) {
      setLoading(false); // Set loading to false once branches are fetched
    }
  }, [posts]); // This effect runs whenever posts change

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent post"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Branches</div>
            <div className={styles.col}>No. of Cards issued</div>
            <div className={styles.col}>No. of Scans</div>
          </div>
          {loading ? (
            <Loader className={styles.loader} />
          ) : posts.length > 0 ? (
            posts.map((branch, index) => (
              <Row item={branch} key={index} sorting={sorting} />
            ))
          ) : (
            <div className={styles.noPosts}>No posts available</div>
          )}
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost />
      </Modal>
    </>
  );
};

export default RecentPost;
