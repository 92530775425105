import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Row from "./Row";
import api from "../../../api";

const Table = ({ className, search }) => {
  const [cardStats, setCardStats] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchCardStats = async (page = 1) => {
    setLoading(true); // Start loading
    try {
      const response = await api.get("/card-stats", {
        params: {
          page,
          per_page: 6, // Define how many records to fetch per request
        },
      });

      setCardStats((prevStats) => [...prevStats, ...response.data.data]);
      setTotalPages(response.data.last_page);
      setCurrentPage(page);
      console.log('Card stats fetched successfully');
    } catch (error) {
      console.error("Error fetching card stats", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchCardStats();
  }, []);

  const filteredStats = cardStats.filter(
    (x) =>
      x.card_id.toLowerCase().includes(search.toLowerCase()) ||
      (x.email && x.email.toLowerCase().includes(search.toLowerCase()))
  );

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      fetchCardStats(currentPage + 1);
    }
  };

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.table)}>
        <div className={cn(styles.row)}>
          <div className={styles.col}>Card No.</div>
          <div className={styles.col}>Card No.</div>
          <div className={styles.col}>Email</div>
          <div className={styles.col}>Number of Scans</div>
        </div>
        {filteredStats.length > 0 ? (
          filteredStats.map((x, index) => (
            <Row item={x} key={index} />
          ))
        ) : (
          <div className={styles.noResults}>No results found</div>
        )}
      </div>
      <div className={styles.foot}>
        <button
          className={cn("button-stroke button-small", styles.button)}
          onClick={handleLoadMore}
          disabled={loading || currentPage >= totalPages}
        >
          {loading ? 'Loading...' : 'Load more'}
        </button>
      </div>
    </div>
  );
};

export default Table;
