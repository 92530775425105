import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import api from "../../../api"; // Assuming api service is available

const ProfileInformation = ({ className }) => {
  const [user, setUser] = useState({});
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState(null);

  useEffect(() => {
    // Fetch user data from localStorage or API
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
      setDisplayName(storedUser.name);
      setEmail(storedUser.email);
    }
  }, []);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSave = async () => {
    // Create a plain object for the data
    const data = {
      name: displayName || "",
      email: email || "",
      image: image ? await toBase64(image) : null // Convert image to base64 if needed
    };

    console.log("data: ", data);

    try {
      const response = await api.put('/user/profile', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json', // Change content type to JSON
        },
      });

      localStorage.setItem('user', JSON.stringify(response.data.user));
      setUser(response.data.user);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  // Function to convert file to base64
  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Profile information"
      classTitle="title-green"
    >
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <img src={user.image} alt="avatar" />
          <button className={styles.remove}>
            <Icon name="close" />
          </button>
        </div>
        <div className={styles.file}>
          <input type="file" onChange={handleFileChange} />
          <button className={cn("button", styles.button)} type="button">
            <Icon name="add" size="24" />
            <span>Upload new picture</span>
          </button>
        </div>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Display name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          required
        />
        <TextInput
          className={styles.field}
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button onClick={handleSave} className="button">Save Changes</button>
      </div>
    </Item>
  );
};

export default ProfileInformation;
