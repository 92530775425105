import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import styles from "./UserChart.module.sass"; // Ensure this file exists in the same directory

ChartJS.register(ArcElement, Tooltip, Legend);

const NewUsersChart = ({ newUsers, totalUsers, month }) => {
  
  if (!newUsers || !totalUsers) {
    newUsers = 0;
    totalUsers = 0;
  }

  var percentage = 0;

  if (totalUsers !== 0) {
    percentage = ((newUsers / totalUsers) * 100).toFixed(2);
  }

  const data = {
    labels: ["New Users", "Existing Users"],
    datasets: [
      {
        data: [newUsers, totalUsers - newUsers],
        backgroundColor: ["#4A90E2", "#E5E5E5"],
        hoverBackgroundColor: ["#357ABD", "#C3C3C3"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "80%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className={styles.chartCard}>
      <h3 className={styles.title}>New Visits</h3>
      <div className={styles.doughnutContainer}>
        <Doughnut data={data} options={options} />
        <div className={styles.percentage}>
          <span>{percentage}%</span>
          <p>New visits</p>
        </div>
      </div>
      <p className={styles.month}>For this month</p>
      <p className={styles.dateRange}>{month}</p>
    </div>
  );
};

export default NewUsersChart;
