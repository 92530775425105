import React, { useState, useEffect } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Row from "./Row";
import NewPost from "./NewPost";
import api from "../../../api";

// Fetch posts function with pagination support
const fetchPosts = async (page = 1, limit = 10) => {
  const response = await api.get(`/scans/by-date?page=${page}&limit=${limit}`);
  return response.data.data;
}

const intervals = ["Last 7 days", "This month", "All time"];

const RecentPost = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [posts, setPosts] = useState([]); // Start with an empty array
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const loadInitialPosts = async () => {
      const initialPosts = await fetchPosts(currentPage);
      setPosts(initialPosts);
      // Get the total pages if available
      const totalResponse = await api.get("/scans/by-date");
      setTotalPages(totalResponse.data.last_page);
    };
    loadInitialPosts();
  }, [currentPage]);

  const calculatePercentageChange = (currentScans, previousScans) => {
    if (!previousScans) return 0;
    const percentageChange =
      ((currentScans - previousScans) / previousScans) * 100;
    return parseFloat(percentageChange.toFixed(2)); // Limit to 2 decimal places
  };

  const loadMorePosts = async () => {
    console.log("Loading more posts...");
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      const newPosts = await fetchPosts(nextPage);
      setPosts((prevPosts) => [...prevPosts, ...newPosts]);
      setCurrentPage(nextPage);
    }
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Performance by day"
        classTitle={cn("title-blue", styles.title)}
        classCardHead={styles.head}
      >
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Scans</div>
          </div>
          {posts.map((x, index) => {
            const previousScans = index > 0 ? posts[index - 1].scans : null;
            const percentageChange = calculatePercentageChange(
              x.scans,
              previousScans
            );

            return (
              <Row
                item={{ date: x.date, scans: x.scans, percentageChange }}
                key={index}
              />
            );
          })}
        </div>
        <div className={styles.foot}>
          <button
            className={cn("button-stroke button-small", styles.button)}
            onClick={loadMorePosts} // Call the loadMorePosts function
            disabled={currentPage >= totalPages} // Disable if there are no more pages
          >
            <span>{currentPage >= totalPages ? "No more posts" : "Load more"}</span>
          </button>
        </div>
      </Card>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <NewPost />
      </Modal>
    </>
  );
};

export default RecentPost;
