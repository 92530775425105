import React from "react";
import styles from "./ProductsDashboard.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "../Home/Overview";
import ProductActivity from "./ProductActivity";
// import ProductViews from "./ProductViews";
import Products from "./Products";
import PerformanceByWeek from "../Customers/TopCountry";
import RecentPost from "../Promote/RecentPost";

const ProductsDashboard = () => {
  return (
    <>
      <div className={styles.section}>
        <Overview className={styles.card} />
        <div className={styles.row}>
          <div className={styles.col}>
            <RecentPost />
          </div>
          <PerformanceByWeek className={styles.card} />
        </div>
        {/* <Products /> */}
      </div>
      <TooltipGlodal />
    </>
  );
};

export default ProductsDashboard;
