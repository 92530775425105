import { useEffect, useState } from "react";
import { progress } from "../utils";
import api from "../api";

export const useBranches = () => {
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await api.get('/branches'); // Adjust the URL as necessary
        const fetchedBranches = response.data.map(branch => ({
          name: branch.name,
          image: branch.image,
          image2x: branch.image, // Assuming you use the same image for 2x
          picture: true,
          socials: branch.socials.map(social => ({
            title: social.title,
            url: social.url,
          })),
          linkClicks: {
            counter: branch.linkClicks.counter,
          },
          views: {
            counter: branch.views.counter,
            balance: branch.views.balance,
            progress: progress(),
          }
        }));
        setBranches(fetchedBranches);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchBranches();
  }, []);

  return branches;
};
