import React from "react";
import styles from "./Row.module.sass";
import Cell from "./Cell";

const RowDevice = ({ item }) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div>{item.date || "No Date"}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Scans</div>
        <Cell
          item={{
            counter: item.scans.toFixed(2),
            progress: item.percentage_change ? item.percentage_change.toFixed(1) : 0, // Use percentage_change for progress
          }}
          blueIndicator
        />
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Android</div>
        <div>{item.android || 0}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>iOS</div>
        <div>{item.ios || 0}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Other</div>
        <div>{item.other || 0}</div>
      </div>
    </div>
  );
};

export default RowDevice;
