import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";

const DropdownOption = ({
    className,
    classDropdownHead,
    classDropdownLabel,
    value,
    setValue,
    options, // Now expecting objects with { value, label }
    label,
    tooltip,
    small,
    upBody,
}) => {
    const [visible, setVisible] = useState(false);

    const handleClick = (optionValue) => {
        setValue(optionValue);
        setVisible(false);
    };

    const selectedOption = options.find((option) => option.value === value);

    return (
        <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            {label && (
                <div className={cn(styles.label, classDropdownLabel)}>
                    {label}{" "}
                    {tooltip && (
                        <Tooltip
                            className={styles.tooltip}
                            title={tooltip}
                            icon="info"
                            place="right"
                        />
                    )}
                </div>
            )}
            <div
                className={cn(
                    styles.dropdown,
                    className,
                    { [styles.small]: small },
                    {
                        [styles.active]: visible,
                    }
                )}
            >
                <div
                    className={cn(styles.head, classDropdownHead)}
                    onClick={() => setVisible(!visible)}
                >
                    <div className={styles.selection}>
                        {selectedOption ? selectedOption.label : "Select an option"}
                    </div>
                </div>
                {visible && (
                    <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
                        {options.map((option, index) => (
                            <div
                                className={cn(styles.option, {
                                    [styles.selectioned]: option.value === value,
                                })}
                                onClick={() => handleClick(option.value)}
                                key={index}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </OutsideClickHandler>
    );
};

export default DropdownOption;
