import React, { useState, useEffect } from "react";
import styles from "./CustomerList.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Filters from "../../components/Filters";
import Table from "./Table";
import Panel from "./Panel";
import Details from "./Details";
import CardFlip from "./CardFlip";
import api from "../../api";

const CustomerList = () => {
  const [search, setSearch] = useState("");

  // State for card images that can be updated in real-time from the backend
  const [frontImage, setFrontImage] = useState("/CARDS.png");
  const [backImage, setBackImage] = useState("/Back_CARD.png");

  // State for NFC card details
  const [cardName, setCardName] = useState("No card found");
  const [creationDate, setCreationDate] = useState(null);
  const [encodedURL, setEncodedURL] = useState(null);
  const [mediaUsed, setMediaUsed] = useState(null);

  useEffect(() => {
    // Simulating a fetch call to load card images and details from backend
    const fetchCardDetails = async () => {
      try {
        // Simulated API response with URLs for front and back images, and other details
        const response = await api.get('/cards/latest/details');
        
        const data = await response.data;

        // Update state with data from the API
        setFrontImage(data.frontImage);
        setBackImage(data.backImage);
        setCardName(data.cardName);
        setCreationDate(data.creationDate);
        setEncodedURL(data.encodedURL);
        setMediaUsed(data.mediaUsed);
      } catch (error) {
        console.error("Error fetching card details", error);
      }
    };

    fetchCardDetails();
  }, []);

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <>
      <div className={styles.page}>
        {/* NFC Card Section */}
        <Card className={styles.nfcCard}>
          <div className={styles.nfcCardContainer}>
            <div className={styles.nfcCardLeft}>
              <CardFlip frontImage={frontImage} backImage={backImage} />
            </div>
            <div className={styles.nfcCardRight}>
              <h4 className={styles.cardName}>{cardName}</h4>
              <p>Created: {creationDate}</p>
              <p>
                The encoded URL is:{" "}
                <a href={encodedURL} target="_blank" rel="noopener noreferrer">
                  {encodedURL}
                </a>
              </p>
              <p>Media Used: {mediaUsed}</p>
            </div>
          </div>
        </Card>

        {/* Products Section */}
        <Card className={styles.productsCard} title="Products">
          <div className={styles.head}>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={handleSubmit}
              placeholder="Search by name or email"
              type="text"
              name="search"
              icon="search"
            />
          </div>

          <div className={styles.tableContainer}>
            <Table className={styles.table} search={search} />
          </div>
        </Card>
      </div>
    </>
  );
};

export default CustomerList;
