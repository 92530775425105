import React from "react";
import cn from "classnames";
import styles from "./PopularProducts.module.sass";
import Card from "../Card";

const PopularProducts = ({ className }) => {
  const branch = JSON.parse(localStorage.getItem('branch')); // Get the full branch object

  // Check if the branch object is available and has the expected properties
  const branch_image_url = branch && branch.image ? branch.image : ""; // Provide a default if image is not found

  return (
    <Card
      className={cn(styles.card, className)}
      title="My Card"
      classTitle="title-blue"
    >
      <div className={styles.popular}>
        <div className={styles.list}>
          {branch_image_url && ( // Only render the image if the URL is valid
            <img
              src={branch_image_url}
              alt="Branch"
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default PopularProducts;
