import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation for redirect state
import styles from "./Login.module.scss"; // Import the SCSS module
import api from "../api";

export default function Login({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const navigate = useNavigate();
  const location = useLocation(); // Use to get the redirect path

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('/login', {
        email,
        password,
      });

      const { token, user, branch, organization } = response.data;

      // Store the token and other data in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));  // Store user as JSON string
      localStorage.setItem('branch', JSON.stringify(branch));  // Store branch as JSON string
      localStorage.setItem('organization', JSON.stringify(organization));  // Store organization as JSON string


      console.log(token);
      console.log(user);

      onLogin(email, password);
      // Check for redirect location state
      const redirectPath =
        location.state?.from?.pathname || "/products/dashboard";
      navigate(redirectPath); // Redirect to admin dashboard or to the intended page
    } catch (error) {
      if (error.response && error.response.data.error) {
        console.error(error);
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h2 className={styles.title}>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label className={styles.label}>Email address</label>
            <input
              type="email"
              className={styles.input}
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Password</label>
            <div className={styles.passwordWrapper}>
              <input
                type={showPassword ? "text" : "password"}
                className={styles.input}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                className={styles.toggleButton}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>

          {/* Error Message */}
          {errorMessage && (
            <div className={styles.errorMessage}>{errorMessage}</div>
          )}

          <button type="submit" className={styles.button}>
            Login
          </button>
        </form>

        <a href="#!" className={styles.link}>
          Forgot password?
        </a>
      </div>
    </div>
  );
}
