import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Item from "./Item";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Chart from "./Chart";
import TotalChart from "./Chart/TotalChart";
import api from "../../../api";

// Sample Data and Constants
const INTERVALS = ["Week", "Month", "Year"];
const TOTAL_SCANS_TITLE = "Total Scans";
const TOTAL_SCANS_ICON = "shopping-bag";
const TOTAL_SCANS_COLOR = "#B1E5FC";

// Helper function to calculate percentage change
const calculatePercentageChange = (current, previous, totalScans) => {
  if (previous === undefined || previous === null) return "N/A";
  if (previous === 0) {
    return current === 0 ? 0 : 100;
  } else {
    const change = ((current - previous) / previous) * 100;
    return change.toFixed(1);
  }
};

// Simulated data fetching function
const fetchData = async (interval) => {
  try {
    const response = await api.get(`/scans/stats/${interval}`);
    return await response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return {
      totalScans: {
        title: TOTAL_SCANS_TITLE,
        counter: "N/A",
        icon: TOTAL_SCANS_ICON,
        color: TOTAL_SCANS_COLOR,
      },
      intervalData: {
        current: { title: "Current", counter: "N/A", value: 0 },
        previous: { title: "Previous", counter: "N/A", value: 0 },
      },
    };
  }
};

const Overview = ({ className }) => {
  const [sorting, setSorting] = useState(INTERVALS[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [navData, setNavData] = useState({});
  const [loading, setLoading] = useState(false);
  const [timeframe, setTimeframe] = useState("monthly");

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await fetchData(sorting);

      // Ensure values are numbers before calculating percentage change
      const currentValue = data.intervalData.current.value || 0; // Default to 0 if undefined
      const previousValue = data.intervalData.previous.value || 0; // Default to 0 if undefined

      // Calculate the percentage change
      const percentageChange = calculatePercentageChange(
        currentValue,
        previousValue,
        data.totalScans.counter
      );
      console.log("currentValue", currentValue);
      console.log("previousValue", previousValue);
      console.log("percentageChange", percentageChange);

      // Add percentage change to current intervalData
      data.intervalData.current.percentageChange = percentageChange;
      setNavData(data);
      setLoading(false);
    };

    getData();
  }, [sorting]);

  useEffect(() => {
    // Set timeframe based on sorting selection
    if (sorting === "Year") setTimeframe("yearly");
    else if (sorting === "Month") setTimeframe("monthly");
    else if (sorting === "Week") setTimeframe("weekly");
  }, [sorting]);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Overview"
      classTitle="title-red"
      head={
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={sorting}
          setValue={setSorting}
          options={INTERVALS}
          small
        />
      }
    >
      <div className={styles.overview}>
        <div className={styles.nav}>
          {loading ? (
            <p>Loading...</p>
          ) : navData.totalScans ? (
            <>
              <Item
                className={cn(styles.item, {
                  [styles.active]: activeIndex === 0,
                })}
                onActive={() => setActiveIndex(0)}
                item={{
                  ...navData.totalScans,
                }}
              />
              <Item
                className={cn(styles.item, {
                  [styles.active]: activeIndex === 1,
                })}
                onActive={() => setActiveIndex(1)}
                item={{
                  ...navData.intervalData.current,
                  percentage: navData.intervalData.current.percentageChange,
                }}
              />
            </>
          ) : (
            <p>No data available</p>
          )}
        </div>
        <div className={styles.body}>
          {activeIndex === 0 && <TotalChart />}
          {activeIndex === 1 && <Chart timeframe={timeframe} />}
        </div>
      </div>
    </Card>
  );
};

export default Overview;
