import React, { useEffect, useState } from "react";
import styles from "./TopCountry.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useDarkMode from "use-dark-mode";
import api from "../../../api";

// Function to fetch data from backend
const fetchWeeklyData = async () => {
  const response = await api.get("/scans/weekly-data");
  return response.data;
};

const PerformanceByWeek = ({ className }) => {
  const darkMode = useDarkMode(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchedData = await fetchWeeklyData();
        setData(fetchedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  // Configurable chart colors
  const gridColor = darkMode.value ? "#272B30" : "#EFEFEF";
  const barColor = "#B5E4CA";
  const tooltipBackgroundColor = "#272B30";
  const tooltipBorderColor = "rgba(255, 255, 255, 0.12)";

  if (loading) {
    return <div>Loading...</div>; // Loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Error handling
  }

  return (
    <Card
      className={cn(styles.card, className)}
      title="Performance by Week"
      classTitle="title-purple"
    >
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            layout="vertical"
            margin={{
              top: 0,
              right: 0,
              left: 5,
              bottom: 0,
            }}
            barSize={24}
            barGap={8}
          >
            <CartesianGrid
              strokeDasharray="none"
              stroke={gridColor}
              horizontal={false}
            />
            <XAxis
              type="number"
              axisLine={false}
              tickLine={false}
              tick={{
                fontSize: 12,
                fontWeight: "500",
                fill: "#6F767E",
              }}
              padding={{ left: 10 }}
            />
            <YAxis
              type="category"
              dataKey="week"
              axisLine={false}
              tickLine={false}
              tickFormatter={(week, index) =>
                `${data[index].month} - ${data[index].week}`
              }
              tick={{
                fontSize: 12,
                fontWeight: "500",
                fill: "#6F767E",
              }}
            />
            <Tooltip
              formatter={(value) => [`${value} scans`, "Scans"]}
              labelFormatter={(label) => {
                const weekData = data.find((d) => d.week === label);
                return `${weekData.startDate} - ${weekData.endDate}`;
              }}
              contentStyle={{
                backgroundColor: tooltipBackgroundColor,
                borderColor: tooltipBorderColor,
                borderRadius: 8,
                boxShadow:
                  "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
              }}
              labelStyle={{
                fontSize: 12,
                fontWeight: "500",
                color: "#fff",
              }}
              itemStyle={{
                padding: 0,
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: "600",
                color: "#fff",
              }}
              cursor={{ fill: "#f3f2f3" }}
            />
            <Bar dataKey="scans" fill={barColor} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default PerformanceByWeek;
