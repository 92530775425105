import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Help from "./Help";
import Image from "../Image";

const navigation = [
  {
    title: "Home menu",
    icon: "home",
    url: "/",
  },
  {
    title: "Scans",
    icon: "scans",
    url: "/products/dashboard",
  },
  {
    title: "Devices",
    icon: "mobile",
    url: "/customers/overview",
  },
  {
    title: "Location",
    icon: "location",
    url: "/shop",
  },
  {
    title: "MyNFCs",
    icon: "pie-chart",
    url: "/customers/customer-list",
  },
  {
    title: "Profile",
    icon: "profile-circle",
    url: "/settings",
  },
];

const Sidebar = ({ className, onClose }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("Main Branch"); // Default branch

  const { pathname } = useLocation();

  const generateUrl = (baseUrl) => {
    // Append selectedBranch to the URL if it's not the Main Branch
    return selectedBranch !== "Main Branch"
      ? `${baseUrl}?branch=${encodeURIComponent(selectedBranch)}`
      : baseUrl;
  };

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <div className={styles.header}>
          <Link className={styles.logo} to="/" onClick={onClose}>
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="Core"
            />
          </Link>
        </div>
        <div className={styles.menu}>
          {navigation.map((x, index) => (
            <NavLink
              className={cn(styles.item, {
                [styles.active]: pathname === generateUrl(x.url),
              })}
              to={generateUrl(x.url)}
              key={index}
              onClick={onClose}
            >
              <Icon name={x.icon} size="24" />
              {x.title}
            </NavLink>
          ))}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
