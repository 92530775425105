import React from "react";
import styles from "./Promote.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import RecentPost from "../PromoteNew/RecentPost/index";
import { Navigate } from "react-router";

const Promote = () => {
  const role = localStorage.getItem("role");

  if (role !== "admin") {
    // return a error page
    return (
      <>
        <div className={styles.section}>
          <h1>Access Denied</h1>
          <p>Sorry, you don't have access to this page.</p>
          {/* <Navigate to="/" /> */}
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.section}>
        <Overview className={styles.card} />
        <RecentPost />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Promote;