import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import User from "./User"; // Import the User component that handles avatar and logout

// Header Component
const Header = ({ onLogout, onOpen }) => { // Add onOpen prop
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(false);
    onOpen(); // Open the sidebar when the header button is clicked
  };

  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={handleClick}></button>
      <div className={styles.control} onClick={() => setVisible(!visible)}>
        {/* User Avatar and Logout Functionality */}
        <User className={styles.user} />
      </div>
    </header>
  );
};

export default Header;
