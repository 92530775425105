import React, { useState, useEffect } from "react";
import styles from "./TopDevice.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import api from "../../../api";

const intervals = ["Day", "Week", "Month", "Year"];
const COLORS = ["#8E59FF", "#83BF6E", "#2A85FF"];

const TopDevice = ({ className }) => {
  const [filter, setFilter] = useState(intervals[2]); // Default to "Month"
  const [data, setData] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialData, setInitialData] = useState({}); // To store all fetched data

  const fetchData = async () => {
    try {
      const response = await api.get('/scans/stats/devices');
      setInitialData(response.data); // Store the full response data
      setData(response.data[filter] || []); // Set the default data or empty array if not available
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Update data when filter changes
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setData(initialData[newFilter] || []); // Safely set data to empty array if not found
  };

  const legend = [
    { title: "Android", icon: "mobile", fill: "#8E59FF" },
    { title: "iOS", icon: "tablet", fill: "#83BF6E" },
    { title: "Other", icon: "desktop", fill: "#2A85FF" },
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Card
      className={cn(styles.card, className)}
      title="Top Device"
      classTitle="title-blue"
    >
      <div className={styles.filter}>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={filter}
          setValue={handleFilterChange}
          options={intervals}
          small
        />
      </div>
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Tooltip
              contentStyle={{
                backgroundColor: "#272B30",
                borderColor: "rgba(255, 255, 255, 0.12)",
                borderRadius: 8,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
              }}
              labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
              itemStyle={{
                padding: 0,
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: "600",
                color: "#fff",
              }}
            />
            <Pie
              data={data}
              cx={140}
              cy={110}
              innerRadius={88}
              outerRadius={110}
              fill="#8884d8"
              paddingAngle={1}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.legend}>
        {legend.map((x, index) => (
          <div className={styles.indicator} key={index}>
            <Icon name={x.icon} size="24" fill={x.fill} />
            <div className={styles.title}>{x.title}</div>
            <div className={styles.percent}>
              {data.find((d) => d.name === x.title)?.value || 0}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default TopDevice;
