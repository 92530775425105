import React, { useState, useEffect } from "react";
import styles from "./RecentPost.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Row from "./Row/RowDevice";
import api from "../../../api";

const intervals = ["Day", "Week", "Month", "Year"];

const RecentPostDevice = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPostsData = async (interval) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/scans/device-data/${interval}`);
      setPosts(response.data);
    } catch (err) {
      setError("Unable to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPostsData(sorting);
  }, [sorting]);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Recent Posts by Device"
      classTitle={cn("title-blue", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          />
        </>
      }
    >
      {loading && <div>Loading...</div>}
      {error && <div className={styles.error}>{error}</div>}
      {!loading && !error && (
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Date</div>
            <div className={styles.col}>Scans</div>
            <div className={styles.col}>Android</div>
            <div className={styles.col}>iOS</div>
            <div className={styles.col}>Other</div>
          </div>
          {posts.map((x) => (
            <Row
              item={{
                date: x.date,
                scans: x.scans,
                android: x.android,
                ios: x.ios,
                other: x.other,
                percentage_change: x.percentage_change, // Pass percentage_change here
              }}
              key={`${x.date}-${x.scans}`}
            />
          ))}
        </div>
      )}
    </Card>
  );
};

export default RecentPostDevice;
