import React, { useState } from "react";
import styles from "./CardFlip.module.sass";

const CardFlip = ({ frontImage, backImage }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={styles.cardContainer} onClick={handleFlip}>
      <div className={`${styles.card} ${isFlipped ? styles.isFlipped : ""}`}>
        <div className={styles.cardFaceFront}>
          <img src={frontImage} alt="Card Front" className={styles.cardImage} />
        </div>
        <div className={styles.cardFaceBack}>
          <img src={backImage} alt="Card Back" className={styles.cardImage} />
        </div>
      </div>
    </div>
  );
};

export default CardFlip;
