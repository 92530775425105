import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item }) => {
  return (
    <div className={cn(styles.row)}>
      <div className={styles.col}>
        {item.card_id} {/* Display Card ID */}
      </div>
      <div className={styles.col}>
        {item.card_id} {/* Display Card ID */}
      </div>
      <div className={styles.col}>
        <div className={styles.details}>
          <div className={styles.email}>{item.email || "N/A"}</div> {/* Display email */}
        </div>
      </div>
      <div className={styles.col}>
        <div className={cn("status-green-dark", styles.purchase)}>
          {item.number_of_scans} {/* Display number of scans */}
        </div>
      </div>
    </div>
  );
};

export default Row;
