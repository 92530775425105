import React, { useEffect, useState } from 'react';
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"; // Added useNavigate here
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Help from "./Help";
import Image from "../Image";
import api from "../../api";
import DropdownOption from '../Dropdown/Dropdown';

const navigation = [
  {
    title: "Head Home ",
    icon: "promotion",
    url: "/promote",
  },
  {
    title: "Branches - Home",
    icon: "home",
    url: "/",
  },
  {
    title: "Scans",
    icon: "diamond",
    url: "/products/dashboard",
  },
  {
    title: "Devices",
    icon: "profile-circle",
    url: "/customers/overview",
  },
  {
    title: "Location",
    icon: "store",
    url: "/shop",
  },
  {
    title: "MyNFCs",
    icon: "pie-chart",
    url: "/customers/customer-list",
  },
  {
    title: "Profile",
    icon: "promotion",
    url: "/settings",
  },
];

const Sidebar = ({ className, onClose }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await api.get('/branchesAll');
        if (response && response.data) {
          setBranches(response.data);
          const defaultBranch = response.data[0] || null;
          if (defaultBranch) {
            setSelectedBranch(defaultBranch.id);
            localStorage.setItem('branch', JSON.stringify(defaultBranch));
          }
        }
      } catch (error) {
        console.error("Failed to fetch branches:", error.message);
      }
    };

    fetchBranches();
  }, []);

  const handleBranchChange = async (branchId) => {
    setSelectedBranch(branchId);
    localStorage.setItem('branch', JSON.stringify(branches.find(branch => branch.id === branchId)));

    try {
      await api.post('/set-branch', { branch_id: branchId });
      console.log("Branch updated successfully");

      // Navigate to the root path after the branch is changed
      navigate('/promote');
    } catch (error) {
      console.error("Failed to set branch:", error);
    }
  };

  const generateUrl = (baseUrl) => {
    return selectedBranch !== "Main Branch"
      ? `${baseUrl}?branch=${encodeURIComponent(selectedBranch)}`
      : baseUrl;
  };

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <div className={styles.header}>
          <Link className={styles.logo} to="/" onClick={onClose}>
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="Core"
            />
          </Link>
          {/* Branch Selector using Dropdown */}
          <div className={styles.branchSelector}>
            <DropdownOption
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              classDropdownLabel={styles.dropdownLabel}
              value={selectedBranch} // The selected branch value
              setValue={handleBranchChange} // Handles the branch change event
              options={branches.map(branch => ({ value: branch.id, label: branch.name }))} // Dropdown options
              label="Select Branch" // Label for dropdown
              small // Adjust size based on need
              tooltip="Choose your branch" // Optional tooltip
              upBody={false} // Optional prop to manage dropdown positioning
            />
          </div>
        </div>
        <div className={styles.menu}>
          {navigation.map((x, index) => (
            <NavLink
              className={cn(styles.item, {
                [styles.active]: pathname === generateUrl(x.url),
              })}
              to={generateUrl(x.url)}
              key={index}
              onClick={onClose}
            >
              <Icon name={x.icon} size="24" />
              {x.title}
            </NavLink>
          ))}
        </div>
        <button className={styles.toggle} onClick={onClose}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={onClose}
      ></div>
    </>
  );
};

export default Sidebar;
