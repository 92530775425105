import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import api from "../../../api";

// The menu items for the profile and logout
const items = [
  {
    menu: [
      {
        title: "Edit profile",
        url: "/settings", // Navigates to the settings page
      },
    ],
  },
  {
    menu: [
      {
        title: "Log out", // Log out option
      },
    ],
  },
];

// User component that handles avatar display and logout functionality
const User = ({ className, setIsAuthenticated, setUserRole }) => {
  const [visible, setVisible] = useState(false); // Toggle visibility for the dropdown menu
  const { pathname } = useLocation(); // Get the current route path
  const navigate = useNavigate(); // Navigation hook
  const user = JSON.parse(localStorage.getItem("user")); // Get the user data from local storage
  const userProfileImage = user?.image || "/images/content/avatar.jpg"; // Get the user image

  // Logout function that triggers the passed onLogout and redirects to login
  const handleLogout = () => {
    setVisible(false); // Close the dropdown menu

    // Call the backend to invalidate the token
    api.post('/logout')
      .then(() => {
        // Clear local storage
        localStorage.clear();

        // Reset authentication state
        if (setIsAuthenticated) {
          setIsAuthenticated(false);
        }
        if (setUserRole) {
          setUserRole(""); // Clear the user role
        }

        // Redirect to login page
        navigate("/");
      })
      .catch((error) => {
        console.error("Error logging out:", error);

        // Even if the logout request fails, still clear local storage and redirect
        localStorage.clear();
        if (setIsAuthenticated) {
          setIsAuthenticated(false);
        }
        if (setUserRole) {
          setUserRole("");
        }
        navigate("/");
      });
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.user, className, {
          [styles.active]: visible, // Toggle class if the menu is visible
        })}
      >
        {/* Avatar button that toggles the dropdown menu */}
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img
            src={userProfileImage}
            alt="Avatar"
            className={styles.avatarImg}
          />
        </button>

        {/* Dropdown menu for Edit Profile and Logout */}
        {visible && (
          <div className={styles.body}>
            {items.map((item, index) => (
              <div className={styles.menu} key={index}>
                {item.menu.map((x, index) =>
                  x.url ? (
                    // Edit profile link
                    <NavLink
                      className={cn(styles.item, {
                        [styles.color]: x.color,
                        [styles.active]: pathname === x.url,
                      })}
                      to={x.url}
                      onClick={() => setVisible(false)} // Close the menu on click
                      key={index}
                    >
                      {x.icon && <Icon name={x.icon} size="24" />}
                      {x.title}
                    </NavLink>
                  ) : (
                    // Log out button
                    <button
                      className={styles.item}
                      onClick={handleLogout} // Trigger the logout function
                      key={index}
                    >
                      {x.icon && <Icon name={x.icon} size="24" />}
                      {x.title}
                    </button>
                  )
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default User;
