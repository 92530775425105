import React from "react";
import styles from "./Row.module.sass";
import Cell from "./Cell";

const Row = ({ item }) => {
  // Ensure item is defined and has the necessary properties
  const counter = item.scans !== undefined ? item.scans : 0; // Default to 0 if undefined
  const progress =
    item.percentageChange !== undefined ? Math.abs(item.percentageChange) : 0; // Default to 0 if undefined
  const balance =
    item.percentageChange !== undefined ? item.percentageChange : 0; // Default to 0 if undefined

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div>{item.date || "No Date"}</div>{" "}
        {/* Provide fallback text if date is undefined */}
      </div>
      <div className={styles.col}>
        <div className={styles.label}>Scans</div>
        <Cell
          item={{
            counter: counter, // Ensure counter is defined
            progress: `${progress.toFixed(2)}%`, // Ensuring two decimal places with percentage sign
            balance: balance.toFixed(2), // Ensuring two decimal places
          }}
          blueIndicator
        />
      </div>
    </div>
  );
};

export default Row;
