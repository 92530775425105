import React from "react";
import styles from "./Home.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "../Customers/Overview";
import PopularProducts from "../../components/PopularProducts";
import Comments from "./Comments";
import LocationMap from "../../components/Mapsjs/LocationMap";

const Home = () => {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Overview className={styles.card} />
          {/* <ProductViews className={styles.card} /> */}
        </div>
        <div className={styles.col}>
          <PopularProducts className={styles.card} views="4" />
          <Comments className={styles.card} />
        </div>
      </div>
      <LocationMap />
    </>
  );
};

export default Home;
