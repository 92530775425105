import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import styles from "./Login.module.sass";
import api from "../../../api";
import Item from "../Item";
import TextInput from "../../../components/TextInput";

const Login = ({ className }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage("New passwords do not match.");
      return;
    }

    try {
      const response = await api.post('/change-password', {
        old_password: oldPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
      });

      setSuccessMessage("Password changed successfully!");
      // Optionally navigate to another page after success
      // navigate("/some-route");
    } catch (error) {
      if (error.response && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Change password"
      classTitle="title-purple"
    >
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Old password"
          name="old-password"
          type="password"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)} // Update state on input change
        />
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="New password"
            name="new-password"
            type="password"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)} // Update state on input change
          />
          <TextInput
            className={styles.field}
            label="Confirm new password"
            name="confirm-password"
            type="password"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)} // Update state on input change
          />
        </div>

        {/* Error and Success Messages */}
        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        {successMessage && <div className={styles.successMessage}>{successMessage}</div>}

        <button
          type="button" // Prevent default form submission
          className={cn("button-stroke", styles.button)}
          onClick={handleUpdatePassword} // Call the function on button click
        >
          Update password
        </button>
      </div>
    </Item>
  );
};

export default Login;
