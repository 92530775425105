import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Chart from "./Chart/TotalScansChart";
import api from "../../../api";

// Possible intervals for dropdown
const intervals = ["Week", "Month", "Year", "All Time"];

const Overview = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [filteredData, setFilteredData] = useState([]); // Filtered data for the chart
  const [totalScans, setTotalScans] = useState(0); // State to hold total scans
  const [scansData, setScansData] = useState(null); // State to hold scans data fetched from the API

  // Fetch scans data from API
  const fetchScansData = async () => {
    try {
      const response = await api.get("/scan-statistics");
      setScansData(response.data); // Set the fetched data into state
    } catch (error) {
      console.error("Error fetching scans data:", error);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchScansData();
  }, []); // Empty dependency array ensures this runs once on mount

  // Update the filtered data and total scans based on the sorting
  useEffect(() => {
    if (scansData) {
      let currentTotalScans = 0;
      let currentFilteredData = [];

      if (sorting === "Week") {
        currentTotalScans = scansData.weeklyTotal;
        currentFilteredData = [
          { name: "Mon", scans: scansData.weekly.mon },
          { name: "Tue", scans: scansData.weekly.tue },
          { name: "Wed", scans: scansData.weekly.wed },
          { name: "Thu", scans: scansData.weekly.thur },
          { name: "Fri", scans: scansData.weekly.fri },
          { name: "Sat", scans: scansData.weekly.sat },
          { name: "Sun", scans: scansData.weekly.sun },
        ];
      } else if (sorting === "Month") {
        currentTotalScans = scansData.monthlyTotal;
        currentFilteredData = [
          { name: "Week 1", scans: scansData.monthly.firstweekofmonth },
          { name: "Week 2", scans: scansData.monthly.secondweekofmonth },
          { name: "Week 3", scans: scansData.monthly.thirdweekofmonth },
          { name: "Week 4", scans: scansData.monthly.fourthweekofmonth },
          { name: "Week 5", scans: scansData.monthly.fifthweekofmonth },
        ];
      } else if (sorting === "Year") {
        currentTotalScans = scansData.yearlyTotal;
        currentFilteredData = [
          { name: "Jan", scans: scansData.yearly.Jan },
          { name: "Feb", scans: scansData.yearly.Feb },
          { name: "Mar", scans: scansData.yearly.Mar },
          { name: "Apr", scans: scansData.yearly.Apr },
          { name: "May", scans: scansData.yearly.May },
          { name: "Jun", scans: scansData.yearly.Jun },
          { name: "Jul", scans: scansData.yearly.Jul },
          { name: "Aug", scans: scansData.yearly.Aug },
          { name: "Sep", scans: scansData.yearly.Sep },
          { name: "Oct", scans: scansData.yearly.Oct },
          { name: "Nov", scans: scansData.yearly.Nov },
          { name: "Dec", scans: scansData.yearly.Dec },
        ];
      } else if (sorting === "All Time") {
        const currentYear = new Date().getFullYear();
        currentTotalScans = scansData.allTimeTotal;
        currentFilteredData = [
          {
            name: `Year ${currentYear}`,
            scans: scansData.all_time[currentYear],
          },
          {
            name: `Year ${currentYear - 1}`,
            scans: scansData.all_time[currentYear - 1],
          },
          {
            name: `Year ${currentYear - 2}`,
            scans: scansData.all_time[currentYear - 2],
          },
          {
            name: `Year ${currentYear - 3}`,
            scans: scansData.all_time[currentYear - 3],
          },
          {
            name: `Year ${currentYear - 4}`,
            scans: scansData.all_time[currentYear - 4],
          },
          {
            name: `Year ${currentYear - 5}`,
            scans: scansData.all_time[currentYear - 5],
          },
          {
            name: `Year ${currentYear - 6}`,
            scans: scansData.all_time[currentYear - 6],
          },
          {
            name: `Year ${currentYear - 7}`,
            scans: scansData.all_time[currentYear - 7],
          },
          {
            name: `Year ${currentYear - 8}`,
            scans: scansData.all_time[currentYear - 8],
          },
          {
            name: `Year ${currentYear - 9}`,
            scans: scansData.all_time[currentYear - 9],
          },
        ];
      }

      setTotalScans(currentTotalScans);
      setFilteredData(currentFilteredData);
    }
  }, [sorting, scansData]); // Re-run effect when sorting or scansData changes

  return (
    <Card
      className={cn(styles.card, className)}
      title="Scans"
      classTitle={cn("title-red", styles.cardTitle)}
      classCardHead={styles.cardHead}
      head={
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={sorting}
          setValue={setSorting}
          options={intervals}
          small
        />
      }
    >
      <div className={styles.overview}>
        <div className={styles.details}>
          <div className={cn("h4", styles.title)}>
            Total Scans: {totalScans} {/* Display total scans */}
          </div>
        </div>
        {scansData && <Chart data={filteredData} />}{" "}
        {/* Pass filtered data to Chart component */}
      </div>
    </Card>
  );
};

export default Overview;
